<template>
  <div>
    <div v-if="illegalDeleteData != null">
      <confirmationDialog
          v-if="showNoticeDialog"
          :value="showNoticeDialog"
          title="Notice"
          @confirmation-ok="showNoticeDialog = false"
          maxWidth="500"
          v-on="$listeners"
          :hideOkButton="true">
        <div>
          <div class="mb-2">{{ illegalDeleteData.result.message }}</div>
          <ul>
            <li v-for="value in illegalDeleteData.result.rows" :key="value.n_id">
              <addField
                  :path="value.url"
                  :additionalAttributes="additionalAttributes(value)"
                  :showDialog="openDialog"
                  @dialog-closed="openDialog = false">
                <a class="elevation-0">{{ getListName(value) }}</a>
              </addField>
            </li>
          </ul>
        </div>
      </confirmationDialog>
    </div>
    <div v-else-if="localResult != null && showCustomDialog">
      <dialogContainer
          :result="localResult"
          :dialogAttributes="{}"
          v-on="$listeners"
      ></dialogContainer>
    </div>
  </div>
</template>
<script>
import dialogContainer from "@/commonComponents/dialogContainer.vue";
//import dynamicComponent from "@/commonComponents/dynamicComponent.vue";
import confirmationDialog from "@/commonComponents/confirmationDialog.vue";
import addField from "@/commonComponents/addField.vue";
import {getRandomInt} from "@/js/helper.js";

export default {
  components: {confirmationDialog, addField, dialogContainer},
  props: ["row", "info", "currentTable", "confirmation", "result"],
  data() {
    return {
      showNoticeDialog: false,
      illegalDeleteData: null,
      openDialog: false,
      localResult: null,
      dialogRef: "dialog" + getRandomInt(0, 1000),
      showCustomDialog: false,
    };
  },

  created() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (
          mutation.type === "closeDialog" &&
          state.dialogRef === this.dialogRef
      ) {
        this.localResult = null;
        this.showCustomDialog = false;
        this.$emit("dialog-closed", false);
      }

      if (
          mutation.type === "submittedResultChanged" &&
          typeof state.submittedResult[this.dialogRef] != "undefined"
      ) {
        this.removeDeletedRow(
            state.submittedResult[this.dialogRef].result.json,
            this.info.deleteCallBackFunction
        );
      }
    });

    this.sendDeleteRequest(
        //  this.row.n_id,
        // this.row.n_id_key,

        this.info.additionalAjaxOptions,
        this.info.additionalData,
        this.info.deleteCallBackFunction
    );
  },

  beforeDestroy() {
    this.unsubscribe();
  },
  watch: {
    confirmation: function () {
      console.log("changed");
      this.sendDeleteRequest(
          // this.row.n_id,
          // this.row.n_id_key,

          this.info.additionalAjaxOptions,
          this.info.additionalData,
          this.info.deleteCallBackFunction
      );
    },
  },
  methods: {
    additionalAttributes(value) {
      return {
        n_id: value.n_id,
        n_id_key: value.n_id_key,
        function: "getInsertedFieldsEdit",
      };
    },
    getListName(value) {
      var nameList;
      if (typeof value.c_name === "undefined") {
        let cType = this.getMappedName(value.c_type);
        // nameList = "n_id: "+value.n_id+" ,Type:  "+value.c_type;
        nameList = "Connection,Type:  " + cType;
      } else {
        // nameList = "n_id: "+value.n_id+" ,Name:  "+ value.c_name;
        nameList = "Name:  " + value.c_name;
      }
      return nameList;
    },
    sendDeleteRequest(
        // n_id,
        // n_id_key,
        additionalAjaxOptions,
        additionalData,
        deleteCallBackFunction
    ) {
      //  var $caller = $(caller);
      // var $dataTable = $caller.closest(".dataTable");
      //   let tableID = $dataTable.attr("id");

      let uri = this.currentTable.tableUrl;
      let indexRow = "n_id";
      if (typeof this.result != "undefined" && typeof this.result.indexRow != "undefined") {
        indexRow = this.result.indexRow;
      }

      // New convention is to use 'id' instead of 'n_id', therefore we apply it here in case it's a new page.
      if (!this.row[indexRow]) indexRow = 'id';

      var data = {
        function: "delete",
        n_id: this.row[indexRow],
        n_id_key: this.row.n_id_key,
        requestType: "ajax",
      };

      if (typeof additionalData == "object") {
        console.log(additionalData);
        data = {...data, ...additionalData};
      }
      // console.log(data);
      let caller = this;
      this.frameworkAxiosRequest({
        method: 'post',
        url: uri,
        data: data,
      })
          .then(function (response) {
            //handle success
            // console.log(response);

            //  success: function(data, textStatus) {
            if (
                typeof response.data.result.json != "undefined" &&
                typeof response.data.result.json.deleted != "undefined"
            ) {
              caller.removeDeletedRow(
                  response.data.result.json,
                  deleteCallBackFunction
              );
            } else {
              caller.$set(response.data.result, "dialogRef", caller.dialogRef);
              caller.localResult = response.data.result;
              caller.showCustomDialog = true;
            }
            // },
          })
          .catch(function (response) {
            //handle error
            console.log(response);
            //           error: function(jqXHR, textStatus, errorThrown) {
            //   let $dialog = getActiveDialogDiv(false);
            //   $dialog.html(jqXHR.responseText);
            //   var dialogOptions = { modal: true, width: 500 };
            //   generateDialog($dialog, dialogOptions, "Notice");
            //   $dialog.attr("data-tableID", tableID);
            //   dialogStackUp($caller, $dialog);
            // }
          });
    },
    removeDeletedRow(data, deleteCallBackFunction) {
      if (data.deleted === "true") {
        //  console.log(this.currentTable);
        let currentTable = this.currentTable;
        let index = -1;
        let indexRow = "n_id";

        // Set 'id' as indexRow for new code that uses the new naming convention
        if (currentTable.data.length > 0 && !(indexRow in currentTable.data[0])) {
          indexRow = "id";
        }

        if (typeof this.result != "undefined" && typeof this.result.indexRow != "undefined") {
          indexRow = this.result.indexRow;
        }
        currentTable.data.forEach((element, i) => {
          if (element[indexRow] === data.n_id) {
            index = i;
          }
        });
        currentTable.data.splice(index, 1);
        this.$store.commit("tableChanged", currentTable);

        if (typeof deleteCallBackFunction != "undefined") {
          deleteCallBackFunction(data.n_id);
        }
        this.$emit("confirmation-ok");
      } else if (data.deleted === "illegal") {
        this.showNoticeDialog = true;
        this.illegalDeleteData = data;
        // let rendered = renderdeleteResult(data, $dataTable).html();
        // if (rendered == "you do not have permission to delete this entry") {
        //   (dialogWidth = "350"), (dialogHeight = "150");
        // }
        // $messageDialog.html(renderdeleteResult(data, $dataTable));
      } else {
        // $messageDialog.html("<div>failed to remove " + n_id + ".</div>");
      }
      if (data.deleted !== "true") {
        //   var options;
        //   if (typeof data.function != "undefined" && data.function == "wipe") {
        //     options = {
        //       modal: true,
        //       width: "350",
        //       height: "150",
        //       title: "Notice"
        //     };
        //   } else {
        //     options = {
        //       modal: true,
        //       width: dialogWidth,
        //       height: dialogHeight,
        //       title: dialogTitle,
        //       buttons: {
        //         OK: {
        //           click: function() {
        //             $(this).dialog("close");
        //           },
        //           text: "OK",
        //           class: "iconOK"
        //         }
        //       }
        //     };
        //   }
        //   $messageDialog.dialog(options);
      }
    },
  },
};
</script>