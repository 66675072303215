var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{attrs:{"no-gutters":"","align":"center","justify":"center"}},[(_vm.row['editable'] === 'true')?_c('v-col',[(typeof _vm.currentTable['customEditButt'] != 'undefined')?_c('dynamicComponent',{attrs:{"type":_vm.currentTable['customEditButt'],"result":_vm.result}}):_vm._e(),_c('addField',{attrs:{"path":this.currentTable.tableUrl,"additionalAttributes":_vm.additionalAttributes,"dialogAttributes":_vm.currentTable['editDialogAttributes'],"showDialog":_vm.openDialog},on:{"dialog-closed":function($event){_vm.openDialog = false}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-square-edit-outline")])],1)],1):(_vm.currentTable.editable == 'true')?_c('v-col',{}):_vm._e(),(
      (typeof _vm.currentTable['actionButtonRowItem'] == 'undefined' ||
        _vm.currentTable['actionButtonRowItem'] === false ||
        _vm.currentTable['actionButtonRowItem'].length == 0) &&
      _vm.row['deletable'] === 'true'
    )?_c('v-col',[_c('tableDeleteButton',{attrs:{"value":_vm.value,"valueKey":_vm.valueKey,"row":_vm.row,"currentTable":_vm.currentTable,"result":_vm.result}})],1):_vm._e(),_vm._l((_vm.currentTable['additionalButtons']),function(type){return _c('v-col',{key:type},[_c('dynamicComponent',{attrs:{"type":type,"result":_vm.result,"additional":{
        value: _vm.value,
        row: _vm.row,
        valueKey: _vm.valueKey,
        currentTable: _vm.currentTable,
        rowindex: _vm.rowindex,
      }}})],1)}),(
      typeof _vm.currentTable['actionButtonRowItem'] != 'undefined' &&
      _vm.currentTable['actionButtonRowItem'] != false
    )?_c('v-col',[_c('actionButtonRow',{attrs:{"row":_vm.row,"currentTable":_vm.currentTable,"rowindex":_vm.rowindex}})],1):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }