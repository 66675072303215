var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(typeof _vm.currentTable['header'][_vm.header]['tableFieldFormatFile'] != 'undefined')?[_c('dynamicComponent',_vm._g({attrs:{"type":_vm.currentTable['header'][_vm.header]['tableFieldFormatFile'],"result":_vm.result,"additional":{  row: _vm.row,  currentTable: _vm.currentTable,  header: _vm.header,  rowindex: _vm.rowindex, }}},_vm.$listeners))]:( _vm.currentTable['searchTestcaseList'] == true &&
                         (_vm.header == 'c_name' ||
                          _vm.header == 'c_description' ||
                          _vm.header == 'name' ||
                          _vm.isset(_vm.currentTable['header'][_vm.header]['editableField'])))?[_c('a',{attrs:{"data-title":_vm.title,"data-type":'row_' + _vm.header},on:{"click":function($event){return _vm.$emit('row-clicked', _vm.row[_vm.result['indexRow']], _vm.row['c_name'])}}},[_vm._v(" "+_vm._s(_vm.title)+" ")])]:(_vm.row['editable'] === 'true' &&
                         _vm.currentTable['header'][_vm.header]['noEdit'] != true &&
                        (_vm.header == 'c_name' ||
                         _vm.header == 'c_description' ||
                         _vm.header == 'name' ||
                         _vm.header == 'c_componentId' ||
                         _vm.isset(_vm.currentTable['header'][_vm.header]['editableField']))
    )?[_c('addField',{attrs:{"path":this.currentTable.tableUrl,"additionalAttributes":_vm.additionalAttributes,"dialogAttributes":_vm.currentTable['editDialogAttributes']}},[_c('p',{attrs:{"data-title":_vm.title,"data-type":'row_' + _vm.header}},[_vm._v(" "+_vm._s(_vm.title)+" ")])])]:[_vm._v(_vm._s(_vm.title)+" ")]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }