<template>
  <actionButtons
    :currentTable="currentTable"
    :row="row"
    :actionButtonsArray="currentTable['actionButtonRowItem']"
    :additional="{selectedRows:selectedRows,rowindex:rowindex}"
  ></actionButtons>
</template>
<script>
import actionButtons from "@/commonComponents/actionButtons.vue";
export default {
  data() {
    return {
      showMenu: false,
    };
  },
  components: { actionButtons },
  computed: {
    selectedRows() {
      let selectedRows = {};
      selectedRows[this.row.n_id] = this.row;
      return selectedRows
    },
  },
  props: ["currentTable", "row","rowindex"],
  methods: {
    closeMenu() {
      console.log("close");
      this.showMenu = false;
    },
  },
};
</script>
