<template>
    <div>
        <template v-if="typeof currentTable['header'][header]['tableFieldFormatFile'] != 'undefined'"
                  :data-type="'row_' + header">
            <dynamicComponent
                    :type="currentTable['header'][header]['tableFieldFormatFile']"
                    :result="result"
                    :additional="{  row: row,  currentTable: currentTable,  header: header,  rowindex: rowindex, }"
                    v-on="$listeners"
            ></dynamicComponent>
        </template>
        <template v-else-if=" currentTable['searchTestcaseList'] == true &&
                             (header == 'c_name' ||
                              header == 'c_description' ||
                              header == 'name' ||
                              isset(currentTable['header'][header]['editableField']))">
              <a @click="$emit('row-clicked', row[result['indexRow']], row['c_name'])"
                :data-title="title"
                :data-type="'row_' + header" >
               {{ title }}
              </a>
        </template>
        <template v-else-if="row['editable'] === 'true' &&
                             currentTable['header'][header]['noEdit'] != true &&
                            (header == 'c_name' ||
                             header == 'c_description' ||
                             header == 'name' ||
                             header == 'c_componentId' ||
                             isset(currentTable['header'][header]['editableField']))
        ">
         <addField
               :path="this.currentTable.tableUrl"
               :additionalAttributes="additionalAttributes"
                :dialogAttributes="currentTable['editDialogAttributes']">
            <p :data-title="title"
               :data-type="'row_' + header">
                    {{ title }}
            </p>
        </addField>
        </template>
        <template v-else
                  class="tablefieldContent"
                  :title="title"
                  :data-type="'row_' + header">{{ title }}
        </template>
    </div>
</template>
<script>
    import dynamicComponent from "@/commonComponents/dynamicComponent.vue";
    import addField from "@/commonComponents/addField.vue";

    export default {
        components: {dynamicComponent, addField},
        data() {
            return {openDialog: false};
        },
        computed: {
            title: function () {
                let title = this.row[this.header];
                if (typeof title == "object" && title != null) {
                    if (typeof title["name"] != "undefined") {
                        title = title["name"];
                    } else {
                        title = "";
                    }
                }
                return title;
            },
            value: function () {
                let indexRow = this.result["indexRow"];
                let value = this.row[indexRow];
                if (
                    typeof value == "object" &&
                    typeof this.row[indexRow]["value"] == "object"
                ) {
                    value = this.row[indexRow]["value"];
                }
                return value;
            },
            valueKey: function () {
                let indexRow = this.result["indexRow"];

                let key = this.row[indexRow + "_key"];

                return key;
            },
            additionalAttributes: function () {
                let additionalAttributes = this.currentTable["editAdditionalAttributes"];
                additionalAttributes = {
                    n_id: this.value,
                    n_id_key: this.valueKey,
                    function: "getInsertedFieldsEdit",
                    ...additionalAttributes,
                };
                return additionalAttributes;
            },
        },
        props: ["row", "header", "currentTable", "result", "rowindex"],
        created: function () {
            this.unsubscribe = this.$store.subscribe((mutation, state) => {
                if (mutation.type === "closeDialog") {
                    this.openDialog = state.dialogClose;
                }
            });
        },
        beforeDestroy() {
            this.unsubscribe();
        },
    };
</script>