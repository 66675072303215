<template>
  <v-row no-gutters align="center" justify="center">
    <v-col v-if="row['editable'] === 'true'" >
      <dynamicComponent
        v-if="typeof currentTable['customEditButt'] != 'undefined'"
        :type="currentTable['customEditButt']"
        :result="result"
      ></dynamicComponent>
      <addField
        :path="this.currentTable.tableUrl"
        :additionalAttributes="additionalAttributes"
        :dialogAttributes="currentTable['editDialogAttributes']"
        :showDialog="openDialog"
        @dialog-closed="openDialog = false"
      >
        <v-icon left>mdi-square-edit-outline</v-icon>
      </addField>
    </v-col>
    <v-col v-else-if="currentTable.editable == 'true'" class=""></v-col>
    <!-- <v-col
      v-if="
        typeof currentTable['actionButtonRowItemFlag'] != 'undefined' &&
        currentTable['actionButtonRowItemFlag'] == 'CAR'
      "
    ></v-col> -->
    <v-col
      v-if="
        (typeof currentTable['actionButtonRowItem'] == 'undefined' ||
          currentTable['actionButtonRowItem'] === false ||
          currentTable['actionButtonRowItem'].length == 0) &&
        row['deletable'] === 'true'
      "
    >
      <tableDeleteButton
        :value="value"
        :valueKey="valueKey"
        :row="row"
        :currentTable="currentTable"
        :result="result"
      ></tableDeleteButton>
    </v-col>
    <!--<v-col
          v-if="typeof currentTable['actionButtonRowItem']!='undefined' && currentTable['actionButtonRowItemFlag']!='CAR'"
    >-->
    <v-col v-for="type in currentTable['additionalButtons']" :key="type" class="">
      <dynamicComponent
        :type="type"
        :result="result"
        :additional="{
          value: value,
          row: row,
          valueKey: valueKey,
          currentTable: currentTable,
          rowindex: rowindex,
        }"
      ></dynamicComponent>
    </v-col>
    <v-col
      v-if="
        typeof currentTable['actionButtonRowItem'] != 'undefined' &&
        currentTable['actionButtonRowItem'] != false
      "
    >
      <actionButtonRow
        :row="row"
        :currentTable="currentTable"
        :rowindex="rowindex"
      ></actionButtonRow>
    </v-col>
  </v-row>
</template>
<script>
import dynamicComponent from "@/commonComponents/dynamicComponent.vue";
import addField from "@/commonComponents/addField.vue";
import tableDeleteButton from "@/commonComponents/tableDeleteButton.vue";
import actionButtonRow from "@/commonComponents/actionButtonRow.vue";

export default {
  components: {
    dynamicComponent,
    addField,
    tableDeleteButton,
    actionButtonRow,
  },

  data: function () {
    return { openDialog: false };
  },
  computed: {
    value: function () {
      let indexRow = this.result["indexRow"];
      let value = this.row[indexRow];
      if (
        typeof value == "object" &&
        typeof this.row[indexRow]["value"] == "object"
      ) {
        value = this.row[indexRow]["value"];
      }
      return value;
    },
    valueKey: function () {
      let indexRow = this.result["indexRow"];

      let key = this.row[indexRow + "_key"];

      return key;
    },
    additionalAttributes: function () {
      let additionalAttributes = this.currentTable["editAdditionalAttributes"];
      additionalAttributes = {
        n_id: this.value,
        n_id_key: this.valueKey,
        function: "getInsertedFieldsEdit",
        ...additionalAttributes,
      };
      return additionalAttributes;
    },
  },
  props: ["currentTable", "row", "result", "rowindex"],
};
</script>
