<template>
  <div>
    <div class="alm-upload-button" @click="openDialog">
      <v-icon>mdi-upload</v-icon>
      <div>
        <a>ALM Upload</a>
      </div>
    </div>
    <yes-no-dialog
        v-model="dialog"
        title="ALM Upload"
        width="450"
        yes-text="Send"
        no-text="Cancel"
        @yes="upload"
        :yes-disabled="loading">
      <div class="alm-upload-checkboxes">
        <x-checkbox v-model="flags.uploadResults" label="Upload Results"/>
        <x-checkbox v-model="flags.vendorAutomated" label="Set ALM tests to 'Vendor automated'"/>
        <x-checkbox v-model="flags.degustResult" label="Set ALM test status to the status of the Degust result"/>
        <x-checkbox v-model="flags.statusEmail" label="Receive status email"/>
      </div>
    </yes-no-dialog>
  </div>
</template>

<script>
import YesNoDialog from '@/components/extended/YesNoDialog';
import XCheckbox from '@/components/basic/XCheckbox';

export default {
  name: 'AlmUpload',
  components: {
    XCheckbox,
    YesNoDialog,
  },
  props: {
    testQueueIds: Array,
  },
  data() {
    return {
      dialog: false,
      loading: false,
      flags: {
        uploadResults: false,
        vendorAutomated: false,
        degustResult: false,
        statusEmail: false,
      },
    };
  },
  methods: {
    openDialog() {
      if (this.testQueueIds.length) {
        this.dialog = true;
      } else {
        this.$store.commit('notification', 'Please select at least one row!');
      }
    },
    upload() {
      this.loading = true;
      this.services.alm.uploadResults({
        testqueue_ids: this.testQueueIds,
        upload_results: this.flags.uploadResults,
        set_vendor_automated: this.flags.vendorAutomated,
        set_test_status: this.flags.degustResult,
        send_mail: this.flags.statusEmail,
      }, () => {
        this.dialog = false;
        this.loading = false;
      }, () => {
        this.$store.commit('notification', 'Upload failed. Please try again.');
        this.loading = false;
      });
    },
  },
};
</script>

<style scoped>
.alm-upload-button {
  display: flex;
  align-items: center;
  height: 24px;
  gap: 4px;
  cursor: pointer;
}

.alm-upload-checkboxes {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
</style>