<template>
  <a v-if="row['deletable'] === 'true'">
    <!-- <v-icon left>mdi-delete</v-icon> -->
    <confirmDeletefield
      :info="{}"
      :tableIcon="true"
      :currentTable="currentTable"
      :row="row"
      :result="result"
    ></confirmDeletefield>
  </a>
</template>
<script>
import confirmDeletefield from "@/commonComponents/dynamic/confirmDeletefield.vue";
export default {
  props: ["row", "value", "valueKey", "currentTable","result"],
  components: { confirmDeletefield },
};
//onclick="{$currentTable['deleteFunctionName']}('{$value}' ,'{sha1 ($hashKey)}',this,{},{$currentTable['additionalDeleteAttributes']},{$currentTable['deleteCallBackFunction']});"
</script>
